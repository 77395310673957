/**
 * Hello
 */

@use '~Styles/base/vars' as v;
@use '~Styles/vendors/include-media' as *;

.hello {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: v.$gap;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../images/blur-bg.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 100% 50%;
    z-index: -1;
  }

  & > * {
    width: 100%;
    max-width: 116rem;
    margin: 0 auto;
  }

  &__desc {
    font-size: 2rem;
    line-height: 2.6rem;
    color: v.$green;

    @include media('>=sm') {
      font-size: 3.2rem;
      line-height: 4.2rem;
      color: v.$blue;
    }

    &:before {
      content: '> ';
    }
  }

  &__hr {
    flex-grow: 1;
    max-height: 19.5rem;
    background-color: transparent;

    @include media('>=sm') {
      max-height: 8.1rem;
    }
  }

  & .code.hljs {
    background-color: transparent;
  }

  &__icon {
    height: 1.3em;
    color: v.$accent-color;
  }
}
