/**
 * Navbar
 */

@use '~Styles/base/vars' as v;
@use '~Styles/base/placeholders';
@use '~Styles/vendors/include-media' as *;

.navbar {
  position: relative;
  display: flex;
  align-items: stretch;

  .footer & {
    justify-content: center;

    @include media('>=xs') {
      justify-content: flex-start;
    }
  }

  &__logo {
    justify-content: flex-start;
    width: 100%;
    max-width: v.$sidebar-width;

    @include media('>=#{v.$mobile-menu-breakpoint}') {
      border-right: v.$border-width solid v.$border-color;
    }

    &:hover,
    &:focus,
    &:active {
      color: v.$menu-hover-color;
    }
  }

  &__text {
    display: none;
    border-right: v.$border-width solid v.$border-color;

    @include media('>=xs') {
      display: block;
      width: 100%;
    }

    @include media('>=sm') {
      width: auto;
    }
  }

  &__logo,
  &__text {
    @extend %block-padding;
    color: v.$menu-color;
    white-space: nowrap;

    .footer & {
      padding-top: 1.4rem;
      padding-bottom: 1.4rem;
    }
  }

  &__nav {
    display: flex;
    justify-content: center;
    width: auto;
    background-color: transparent;

    @include media('>=xs') {
      justify-content: flex-end;
    }

    @include media('>=sm') {
      justify-content: flex-start;
    }

    @include media('>=#{v.$mobile-menu-breakpoint}') {
      width: 100%;
    }

    .footer & {
      border-left: v.$border-width solid v.$border-color;
      border-right: v.$border-width solid v.$border-color;

      @include media('>=xs') {
        border-left: none;
        border-right: none;
      }
    }

    &_collapse {
      position: absolute;
      top: calc(100% + v.$border-width);
      right: 0;
      flex-direction: column;
      justify-content: flex-start;
      width: 0;
      height: calc(var(--main-node-height) + v.$border-width);
      background-color: v.$layout-bg-color;
      opacity: 0;
      @extend %transition;

      @include media('>=#{v.$mobile-menu-breakpoint}') {
        position: unset;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: auto;
        background-color: transparent;
        opacity: 1;
      }
    }
  }
}

.hamburger {
  display: flex;
  align-items: center;
  margin-left: auto;
  @extend %block-padding;
  line-height: 1;
  cursor: pointer;

  @include media('>=#{v.$mobile-menu-breakpoint}') {
    display: none;
  }

  &__line {
    position: relative;
    width: 1.8rem;
    height: 0.2rem;
    background-color: v.$menu-color;
    @extend %transition;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: v.$menu-color;
      @extend %transition;
    }

    &:before {
      top: -0.7rem;
    }

    &:after {
      top: 0.7rem;
    }

    .hamburger:hover & {
      background-color: v.$menu-hover-color;

      &:before,
      &:after {
        background-color: v.$menu-hover-color;
      }
    }

    .menu-toggle:checked ~ .hamburger & {
      background-color: transparent;

      &:before {
        top: 0;
        width: 110%;
        height: 110%;
        transform: rotate(135deg);
      }

      &:after {
        top: 0;
        width: 110%;
        height: 110%;
        transform: rotate(225deg);
      }
    }
  }
}

.menu-toggle {
  display: none;

  &:checked ~ .navbar__nav_collapse {
    width: 100%;
    opacity: 1;
    z-index: 1;
  }
}
