/**
 * Projects
 */

@use '~Styles/base/vars' as v;
@use '~Styles/base/placeholders';
@use '~Styles/vendors/include-media' as *;

.projects {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  @extend %block-padding;

  @include media('>=sm') {
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  }

  @include media('>=lg') {
    gap: 4rem;
    padding: 12rem;
  }
}

.project {
  display: flex;
  flex-direction: column;
  max-width: 100%;

  @include media('>=sm') {
    max-width: 37rem;
  }

  &__title {
    color: v.$blue;
    font-weight: 700;

    .comment {
      font-weight: v.$body-font-weight;

      @supports (font-variation-settings: normal) {
        font-weight: v.$body-font-vf-weight;
      }
    }
  }

  &__card {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 1.4rem;
    background-color: v.$code-bg-color;
    border: v.$border-width solid v.$border-color;
    border-radius: v.$border-radius-2x;
    overflow: hidden;
  }

  &__img {
    min-width: 100%;
    height: auto;
  }

  &__tags {
    position: absolute;
    top: 1.7rem;
    right: 1.7rem;
    display: flex;
  }

  &__tag {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.8rem;
    height: 2.8rem;
    margin-left: 0.8rem;
    padding: 0.5rem;
    border-radius: 0.2rem;

    &_js {
      color: v.$text-dark-color;
      background-color: #f7df1e;
    }
    &_html {
      color: v.$text-color;
      background-color: #e34f26;
    }
    &_pug {
      color: v.$text-color;
      background-color: #a86454;
    }
    &_scss {
      color: v.$text-color;
      background-color: #bf4080;
    }
    &_php {
      color: v.$text-dark-color;
      background-color: #8892be;
    }
    &_react {
      color: v.$text-dark-color;
      background-color: #61dafb;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 3rem;

    & > *:not(:first-child) {
      margin-top: v.$gap-2x;
    }
  }

  &__desc {
    flex-grow: 1;
    color: v.$inverted-text-color;
  }

  &__buttons {
    display: flex;
    flex-direction: column;

    & > *:not(:first-child) {
      margin-top: v.$gap;
    }

    @include media('>=xs') {
      flex-direction: row;
      justify-content: space-between;

      & > *:not(:first-child) {
        margin-top: 0;
      }
    }
  }

  &_hidden {
    display: none;
  }
}
