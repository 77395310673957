/**
 * Filter
 */

@use '~Styles/base/vars' as v;
// @use '~Styles/vendors/include-media' as *;
@use '~Styles/base/placeholders';

.filter {
  &__toggle {
    @extend %hidden;
  }

  &__label {
    display: flex;
    align-items: center;

    &:before {
      content: '';
      display: flex;
      justify-content: center;
      width: 2rem;
      height: 2rem;
      margin: 1rem;
      font-size: 4rem;
      background-color: transparent;
      border: v.$border-width solid v.$inverted-text-color;
      border-radius: 50%;
      @extend %transition;
      overflow: hidden;
    }

    &:hover:before {
      box-shadow: 0 0 0.2rem 0.2rem rgba(v.$inverted-text-color, 0.3);
    }

    & > span {
      padding-left: 1.4rem;
    }
  }
}

[class~='filter__toggle']:checked ~ [class~='filter__label'] {
  @extend %file-active;

  &:before {
    content: '•';
    color: v.$text-color;
    background-color: v.$inverted-text-color;
    border-color: v.$inverted-text-color;
    box-shadow: 0 0 0.2rem 0.2rem rgba(v.$inverted-text-color, 0.3);
  }
}
